import { InputText } from "primereact/inputtext";
import React, { useState } from "react";

type IProps = {
  header?: string;
  data?: any;
  field?: string;
  onChange?: any;
};

const ButtonField = (props: IProps) => {
  const { header, data, field, onChange } = props;

  const [gradientColor, setGradientColor] = useState({
    from: "",
    via: "",
    to: "",
  });

  const handleChange = (name: string, value: any) => {
    const _data = {
      ...data,
      [name]: value,
    };
    onChange("primary", _data);
  };

  const handleChangeColor = (name: string, value: any) => {
    if (name !== "from") {
      if (gradientColor.from) {
        const _data = {
          ...data,
          [field]: `linear-gradient(to right, ${gradientColor.from}, ${
            gradientColor.via && `${gradientColor.via},`
          } ${value})`,
        };
        onChange("primary", _data);
      }
      setGradientColor((prev: any) => ({
        ...prev,
        [name]: value,
      }));
    } else {
      setGradientColor((prev: any) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  return (
    <div className="field col-12">
      <label className="mr-2 font-bold" htmlFor="header">
        {header}
      </label>
      <div className="flex align-items-center justify-content-center">
        <div className="flex align-items-center">
          <InputText
            value={gradientColor?.from || ""}
            onChange={(e) => handleChangeColor("from", e.target.value)}
            className="w-6rem"
          />
          <input
            type="color"
            value={gradientColor?.from || ""}
            onChange={(e) => handleChangeColor("from", e.target.value)}
            className=" w-1rem h-1rem p-0 border-none outline-none"
          />
          <span>{`-->`}</span>
        </div>
        <div className="flex align-items-center ml-2">
          <InputText
            value={gradientColor?.via || ""}
            onChange={(e) => handleChangeColor("via", e.target.value)}
            className="w-6rem"
          />
          <input
            type="color"
            value={gradientColor?.via || ""}
            onChange={(e) => handleChangeColor("via", e.target.value)}
            className=" w-1rem h-1rem p-0 border-none outline-none"
          />
          <span>{`-->`}</span>
        </div>
        <div className="flex align-items-center ml-2">
          <InputText
            value={gradientColor?.to || ""}
            onChange={(e) => handleChangeColor("to", e.target.value)}
            className="w-6rem"
          />
          <input
            type="color"
            value={gradientColor?.to || ""}
            onChange={(e) => handleChangeColor("to", e.target.value)}
            className=" w-1rem h-1rem p-0 border-none outline-none"
          />
        </div>
      </div>
      <br />
      <div className="flex flex-column align-items-center">
        <div
          className="w-8rem h-8rem border-round"
          style={{
            backgroundImage: data[field],
          }}
        ></div>
      </div>
      <br />
      <div className="flex justify-content-center">
        <InputText
          value={data[field] || ""}
          onChange={(e) => handleChange(field, e.target.value)}
          className="w-full"
        />
      </div>
    </div>
  );
};

export default ButtonField;
